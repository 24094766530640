import * as yup from 'yup'
import { EVENT_FORM } from '../../common'
import { isGreaterTime } from '@shared/utils'

const TRANSLATION_BASE_KEY = 'agenda.formModal.labels'

export const useSchemaUnavailableSlot = (t) => {
  return yup.object().shape({
    [EVENT_FORM.appointmentType]: yup
      .number()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.appointmentType`)),
    [EVENT_FORM.unavailableSlotType]: yup
      .number()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.unavailableSlotType`)),
    [EVENT_FORM.appointmentDay]: yup
      .string()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.appointmentDay`)),
    [EVENT_FORM.allDay]: yup.boolean(),
    [EVENT_FORM.startDate]: yup
      .string()
      .when(EVENT_FORM.allDay, ([allDay], schema) => {
        return allDay ? schema : schema.required()
      })
      .label(t(`${TRANSLATION_BASE_KEY}.startDate`)),
    [EVENT_FORM.endDate]: yup
      .string()
      .when(EVENT_FORM.allDay, ([allDay], schema) => {
        return allDay
          ? schema
          : schema
              .required()
              .test(
                'is-greater',
                t(`agenda.formModal.formError.minEndDate`),
                (value, { parent }) => isGreaterTime(value, parent.startDate)
              )
      })
      .label(t(`${TRANSLATION_BASE_KEY}.endDate`)),
  })
}
