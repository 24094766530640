import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Printer, DocumentAttachment } from '@carbon/icons-react'
import { Button, BUTTON_TYPES, Switcher } from '@shared/ui'

type Props = {
  title?: string
  subTitle?: string
  dataPostal?:string
  notificationId?: string
  status: 'off' | 'on' | 'disabled'
  handleToggleChange: (state: 'off' | 'on' | 'disabled') => void
  icon?: 'print' | 'attach'
  cta?: 'cta_button' | 'cta_checkbox' | 'cta_toggle'
  disabled?: boolean
}

const getIcon = (icon?: 'print' | 'attach') => {
  const iconSize = 20
  const className = "mr-2"

  switch (icon) {
    case 'print':
      return <Printer size={iconSize} className={className} />
    case 'attach':
      return <DocumentAttachment size={iconSize} className={className} />
    default:
      return null
  }
}

const getStatusLabel = (status: 'off' | 'on' | 'disabled', t: any) => {
  const labels = {
    'off': t('analysis:toggleOptions.offLabel'),
    'on': t('analysis:toggleOptions.onLabel'),
    'disabled': t('analysis:toggleOptions.disabledLabel')
  }

  return labels[status] || labels['disabled']
}

const getCTA = (
  cta?: 'cta_button' | 'cta_checkbox' | 'cta_toggle',
  status?: 'off' | 'on' | 'disabled',
  handleToggleChange?: (state: 'off' | 'on' | 'disabled') => void,
  disabled?: boolean,
  label?: string,
  dataSentPostal?:string
) => {
  switch (cta) {
    case 'cta_button':
      return <Button type={BUTTON_TYPES.submit}>{cta}</Button>
    case 'cta_toggle':
      return (
        <Switcher
          label={label}
          onChange={handleToggleChange}
          status={status}
          disabled={status==='disabled' && true}
          dataSentPostal={dataSentPostal}
        />
      )
    default:
      return null
  }
}

export const NotificationsOptions: FC<Props> = observer(
  ({
    title,
    subTitle,
    dataPostal,
    notificationId,
    handleToggleChange,
    icon,
    status,
    cta,
    disabled
  }) => {
    const { t } = useTranslation()
    const iconElement = getIcon(icon)
    const label = getStatusLabel(status, t)
    const ctaElement = getCTA(cta, status, handleToggleChange, disabled, label, dataPostal)

    return (
      <div className="mt-auto h-[100px] w-full bg-white shadow-md border border-gray-200 rounded-md" data-testid={notificationId}>
        <div className="grid grid-cols-[80%_20%] items-start gap-0 p-6 md:p-3 lg:p-3 xl:p-5 custom-xl:p-6">
          <div>
            <div className="flex items-start mb-3">
              {iconElement}
              <div className="text-black text-[16px] font-medium font-montserrat leading-[20px]">
                {title}
              </div>
            </div>
            <div className="text-black text-[14px] font-normal font-montserrat leading-[21px]">
              {subTitle}
            </div>
          </div>
          <div className="flex items-end">
            {ctaElement}
          </div>
        </div>
      </div>
    )
  }
)
