import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { PDFViewer } from '@react-pdf/renderer'

import { useRouteState } from '@shared/utils'
import { PDFPreviewRouteState } from './state'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { Button, BUTTON_VARIANTS, NotificationsOptions, ReportPdf } from '@shared/ui'
import { ErrorList } from './components'

import { SRErrorModal, SRModal } from '@sr/ui'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'

import { CheckmarkFilled } from '@carbon/icons-react'

import './styles.scss'

type TestParams = {
  page: number
  id: string
}

type Props = {
  name: string
  onSelectTest: (testParams: TestParams) => void
  onPreviewError: () => void
  testOrder: string
  isCompleting: boolean
  statusPostal: 'off' | 'on' | 'disabled'
  sendByPostal: () => void
  dataPostal?: string
  // statusPortal={initialStatusPortal}
  // statusEmail={initialStatusEmail}
  reportType: number
}

export const PDFPreview: FC<Props> = observer(({ 
    onSelectTest, 
    onPreviewError, 
    isCompleting, 
    statusPostal,
    sendByPostal,
    dataPostal,
    // statusPortal,
    // statusEmail
    reportType }) => {
    const { testId } = useParams<{ testId: string }>()
    
    const { t } = useTranslation()

    const [hasSended, setHasSended] = useState(false)

    const { setIsLoading } = useLayoutContext()
    const { hasError, isLoading, testList, resultsParsed } = useRouteState(PDFPreviewRouteState, { testId })

    const pdfData = useMemo(() => resultsParsed, [resultsParsed])

    const handleToggleChange = (state: 'off' | 'on' | 'disabled') => {
      setHasSended(state === 'on')
      // setIsPostalMailDisabled(state !== 'on')
    }

    const closeModal = () => setHasSended(false)

    const handleSendedPostal = async () => {
      setHasSended(false)
      sendByPostal()
    }

    // const handleSendedPortal = async () => {
    //   setHasSended(false)
    //   sendByPortal()
    // }

    // const handleSendedEmail = async () => {
    //   setHasSended(false)
    //   sendByEmail()
    // }

    return (
      <>
        <div className="preview">
          <div className="py-0 px-6 w-3/6 flex flex-col h-full">
            <ErrorList list={testList} onSelectTest={onSelectTest} />
            { statusPostal && (
              <NotificationsOptions 
                title={t('userArea.notifications.postalEmailTitle')}
                subTitle={t('userArea.notifications.postalEmailSubTitle')}
                notificationId='postal-mail'
                handleToggleChange={handleToggleChange}
                icon="print"
                cta="cta_toggle"
                status={statusPostal}
                dataPostal={dataPostal}
              />
            )}

            {/* // Portal
            <NotificationsOptions 
              title={t('userArea.notifications.postalEmailTitle')}
              subTitle={t('userArea.notifications.postalEmailSubTitle')}
              notificationId='postal-mail'
              handleToggleChange={handleToggleChange}
              icon="print"
              cta="cta_toggle"
              statusPostal={statusPostal}
            />
            // Email
            <NotificationsOptions 
              title={t('userArea.notifications.postalEmailTitle')}
              subTitle={t('userArea.notifications.postalEmailSubTitle')}
              notificationId='postal-mail'
              handleToggleChange={handleToggleChange}
              icon="print"
              cta="cta_toggle"
              statusPostal={statusPostal}
            /> */}
          </div>

          {hasSended && (
            <SRModal onClose={closeModal}>
              <div className="flex flex-col justify-center items-center gap-4 mb-4 text-primary" data-testid="agenda-schedule-modal">
                <CheckmarkFilled size={50} />
                <div className="font-normal text-xl">
                  {t('userArea.notifications.modal.postalEmailDescription')}
                </div>
                <div className="flex flex-row align-end gap-2 justify-end w-full">
                  <Button variant={BUTTON_VARIANTS.outlined} onClick={closeModal}>
                    {t('common:cancel')}
                  </Button>
                  <Button onClick={handleSendedPostal}>
                    {t('common:continue')}
                  </Button>
                </div>
              </div>
            </SRModal>
          )}

          {/* {hasSended && (
            <SRModal onClose={closeModal}>
              <div className="flex flex-col justify-center items-center gap-4 mb-4 text-primary" data-testid="agenda-schedule-modal">
                <CheckmarkFilled size={50} />
                <div className="font-normal text-xl">
                  {t('userArea.notifications.modal.postalEmailDescription')}
                </div>
                <div className="flex flex-row align-end gap-2 justify-end w-full">
                  <Button variant={BUTTON_VARIANTS.outlined} onClick={closeModal}>
                    {t('common:cancel')}
                  </Button>
                  <Button onClick={handleSendedPostal}>
                    {t('common:continue')}
                  </Button>
                </div>
              </div>
            </SRModal>
          )}

          {hasSended && (
          <SRModal onClose={closeModal}>
            <div className="flex flex-col justify-center items-center gap-4 mb-4 text-primary" data-testid="agenda-schedule-modal">
              <CheckmarkFilled size={50} />
              <div className="font-normal text-xl">
                {t('userArea.notifications.modal.postalEmailDescription')}
              </div>
              <div className="flex flex-row align-end gap-2 justify-end w-full">
                <Button variant={BUTTON_VARIANTS.outlined} onClick={closeModal}>
                  {t('common:cancel')}
                </Button>
                <Button onClick={handleSendedPostal}>
                  {t('common:continue')}
                </Button>
              </div>
            </div>
          </SRModal>
          )} */}

          <div className="preview__pdf-box">
            {isCompleting || isLoading ? (
              <div />
            ) : (
              <PDFViewer className="preview__pdf-container" showToolbar={false}>
                <ReportPdf type={reportType} results={pdfData} />
              </PDFViewer>
            )}
          </div>
        </div>

        {hasError && (
          <SRErrorModal
            onRetry={onPreviewError}
            backUrl={`../${ROUTE_PATH_CAMPAIGN.userNewTest}`}
            mainMessage="analysis:errorModal.previewError"
          />
        )}
      </>
    )
  },
)
