import {
  FieldValues,
  FormProvider,
  UseFormReturn,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObject, ObjectSchema } from 'yup'
import { FC, ReactElement, ReactNode } from 'react'

interface FormProps {
  className?: string
  defaultValues?: unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema?: ObjectSchema<FieldValues, AnyObject, any, ''>
  onSubmit?: (data: unknown) => void
  children?: JSX.Element | ReactElement | ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>
  dataTestId?: string
}

export const Form: FC<FormProps> = ({
  className,
  defaultValues,
  schema,
  methods = null,
  onSubmit = () => ({}),
  children = null,
  dataTestId = null
}) => {
  const defaultMethods = useForm({
    resolver: schema ? yupResolver(schema) : undefined,
    defaultValues: defaultValues || {},
  })
  const formMethods = { ...defaultMethods, ...methods }
  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form
        className={className}
        onSubmit={handleSubmit((data: unknown) => onSubmit(data))}
        data-testid={dataTestId}
      >
        {children}
      </form>
    </FormProvider>
  )
}
