import {
  GenericAnalysisModel,
  GenericAnalysisTest,
  GenericAnalysisResults,
} from '@sr/data-access'
import { flow, types } from 'mobx-state-tree'
import { actionsCampaign, actionsAnalysis } from '@sr/routes/common'
import { BaseRouteState } from '@shared/data-access'
import { ReportTypeName, loadNs } from '@shared/ui'
import i18next from 'i18next'

export const PDFPreviewRouteState = BaseRouteState.named('PDFPreviewRouteState')
  .props({
    testId: types.string,
    test: types.maybeNull(types.reference(GenericAnalysisModel)),
    testList: types.maybeNull(
      types.array(types.reference(GenericAnalysisTest)),
    ),
    testResults: types.maybeNull(types.reference(GenericAnalysisResults)),
    hasError: false,
  })
  .views((self) => ({
    get resultsParsed() {
      if (!self.testResults?.parsed) return {}

      return {
        results: {
          initialAnalysisList: self.testResults?.parsed,
          testDate: self.testResults?.genericAnalysisDate,
          modificationDate: self.testResults?.genericAnalysisResultsDate,
        },
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('PDFPreviewRouteState')
      try {
        yield self.loadTest()
        yield loadNs({
          i18n: i18next,
          ns: [
            'initial-report',
            `report-${ReportTypeName[self.test?.genericAnalysisType]}`,
          ],
        })
        yield self.test?.isFilled ? self.createResults() : self.setErrors()
      } finally {
        self.removeLoading('PDFPreviewRouteState')
      }
    }),
    createResults: flow(function* createResults() {
      self.setLoading('createResults')
      try {
        yield self.generateTestResults()
      } finally {
        self.removeLoading('createResults')
      }
    }),
    setErrors: flow(function* setErrors() {
      self.setLoading('setErrors')
      try {
        yield self.loadTestsList()
      } finally {
        self.removeLoading('setErrors')
      }
    }),
    ...actionsAnalysis(self),
    ...actionsCampaign(self),
  }))
