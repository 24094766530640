import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'

type InputProps = {
  children?: unknown
  id?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  name: string
  type?: string
  min?: number
  max?: number
  disabled?: boolean
  className?: string
  autoFocus?: boolean
  autoComplete?: string
  onBlur?: () => void
  onChange?: ((e: ChangeEvent<HTMLInputElement>) => void) | (() => void)
  onKeyDown?: () => void
  setIsChanged?: (changed: boolean) => void
  isChecked?: boolean
}

export const Input: FC<InputProps> = ({
  name,
  value,
  defaultValue,
  placeholder,
  id,
  disabled,
  autoFocus,
  autoComplete,
  onBlur,
  className,
  onChange,
  onKeyDown,
  type,
  min,
  max,
  setIsChanged,
  isChecked,
}) => {
  const { register } = useFormContext()
  const uniqueId = `inputText${id ? id : name}`

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setIsChanged && e.target.value !== value) {
      setIsChanged(true)
    }
    if (onChange) {
      if (onChange.length === 0) {
        // Si onChange no espera argumentos
        const func = onChange as () => void
        func() // Llama a onChange sin argumentos
      } else {
        // Si onChange espera un evento
        const func = onChange as (e: ChangeEvent<HTMLInputElement>) => void
        func(e) // Llama a onChange con el evento como argumento
      }
    }
  }

  return (
    <input
      {...register(name, {
        onBlur,
        onChange: handleOnChange,
        min,
        max,
        disabled,
      })}
      id={uniqueId}
      data-testid={`inputText${name}`}
      alt={uniqueId}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      className={className}
      checked={isChecked}
      type={type}
      min={min}
      max={max}
      onKeyDown={onKeyDown}
    />
  )
}
