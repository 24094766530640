import { getWorkDayFromToday } from '@shared/utils'
import {
  EXTRA_DAY_DIGITAL_FIRST_WEEK,
  EXTRA_DAY_DIGITAL_SECOND_WEEK,
  EXTRA_DAY_NO_DIGITAL_FIRST_WEEK,
  EXTRA_DAY_NO_DIGITAL_SECOND_WEEK,
} from './enums'

export const getStartDateWeek1 = ({ noDigital }) => {
  return noDigital
    ? getWorkDayFromToday(EXTRA_DAY_NO_DIGITAL_FIRST_WEEK)
    : getWorkDayFromToday(EXTRA_DAY_DIGITAL_FIRST_WEEK)
}

export const getStartDateWeek2 = ({ noDigital }) => {
  return noDigital
    ? getWorkDayFromToday(EXTRA_DAY_NO_DIGITAL_SECOND_WEEK)
    : getWorkDayFromToday(EXTRA_DAY_DIGITAL_SECOND_WEEK)
}

export const getFiltersObject = ({ noDigital }) => ({
  date: noDigital
    ? getWorkDayFromToday(EXTRA_DAY_NO_DIGITAL_FIRST_WEEK).toDate()
    : getWorkDayFromToday(EXTRA_DAY_DIGITAL_FIRST_WEEK).toDate(),
  noDigital,
})
