import { Image, Text, View } from '@react-pdf/renderer'
import {
  ListItem,
  SubSection,
  Tips,
  TitleIcon,
  TranscriptionScriptPDF,
} from '../../../../components'
import { FC } from 'react'
import {
  AreaColor,
  COGNITIVE_AND_SOCIAL_CLASSES,
  tw,
} from '../../../../utils/styles'
import { useReportPdfContext } from '../../../../provider'
import cognitiveImg from '../../../../assets/cognitive/cognitive-white.png'
import { Tips as AdditionalTips } from '../../../../../sr-initial-report-pdf/components/Tips'
import {
  ADDITIONAL_GUIDELINES_HALLUCINATIONS,
  ADDITIONAL_GUIDELINES_SENSITIVITY,
} from './enum'
import { areaColorKey } from '../../../../../sr-initial-report-pdf/utils'

type PfeifferGuidelinesProps = {
  sectionLetter: string
}

export const PfeifferGuidelines: FC<PfeifferGuidelinesProps> = ({
  sectionLetter,
}) => {
  const { t, recommendations } = useReportPdfContext()
  const areaColor = areaColorKey.blueGreen
  const TRANSLATION_BASE_KEY = 'initial-report:sectionCognitive.guidelines'

  const list =
    t(`${TRANSLATION_BASE_KEY}.activities.list`, {
      returnObjects: true,
    }) || []

  const includesSensitivity = recommendations?.COGNITIVE?.includes(
    'rec_cognitive_tact_sensitivity',
  )
  const includesHallucinations = recommendations?.COGNITIVE?.includes(
    'rec_cognitive_hallucinations',
  )

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.guidelines.title', {
          letter: sectionLetter,
        })}
      >
        <View style={tw('flex flex-col gap-6')}>
          <View style={tw('flex flex-col gap-6')}>
            <View style={tw('flex flex-col gap-6')}>
              <Text>{t(`${TRANSLATION_BASE_KEY}.description`)}</Text>
              {includesSensitivity || includesHallucinations ? (
                <TitleIcon
                  title={t(`${TRANSLATION_BASE_KEY}.altTitle`)}
                  icon={<Image style={tw('w-5 h-5')} src={cognitiveImg} />}
                  areaColor={AreaColor.cognitive}
                />
              ) : (
                <TitleIcon
                  title={t(`${TRANSLATION_BASE_KEY}.title`)}
                  icon={<Image style={tw('w-5 h-5')} src={cognitiveImg} />}
                  areaColor={AreaColor.cognitive}
                />
              )}
            </View>
            <Tips classes={COGNITIVE_AND_SOCIAL_CLASSES}>
              {recommendations?.COGNITIVE_GUIDELINES || ''}
            </Tips>
            {includesSensitivity &&
              ADDITIONAL_GUIDELINES_SENSITIVITY?.list?.map((tip) => {
                return (
                  <AdditionalTips
                    areaColor={areaColor}
                    key={tip.id}
                    title={tip.title}
                  >
                    <Text>{tip.content}</Text>
                  </AdditionalTips>
                )
              })}
            {includesHallucinations &&
              ADDITIONAL_GUIDELINES_HALLUCINATIONS?.list?.map((tip) => {
                return (
                  <AdditionalTips
                    areaColor={areaColor}
                    key={tip.id}
                    title={tip.title}
                  >
                    <Text>{tip.content}</Text>
                  </AdditionalTips>
                )
              })}
          </View>
          <TranscriptionScriptPDF
            script={`${TRANSLATION_BASE_KEY}.activities.description`}
          />
          <ListItem
            title={t(`${TRANSLATION_BASE_KEY}.activities.title`)}
            hasBreak
            style={tw(`bg-${AreaColor.cognitiveLight} p-4`)}
            list={list as unknown as string[]}
          />
        </View>
      </SubSection>
    </View>
  )
}
