import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { Form, getFiltersObject } from '@shared/ui'
import { originalFormatDate, useRouteState, getLocalStorageItem, setLocalStorageItem } from '@shared/utils'
import { SRUserName } from '@sr/ui'

import { useLayoutContext } from '../../common'
import {
  ConfirmationModal,
  ScheduleAgendaLayout,
  ScheduleNoDigitalCheckbox,
} from './components'

import { FORM_NAME } from './enums'
import { FormValues } from './interfaces'
import { ScheduleRouteState } from './state'

export const Schedule = observer(() => {
  const { id } = useParams<{ id: string }>()
  const { setIsLoading } = useLayoutContext()

  const {
    policy,
    isLoading,
    setScheduleFilters,
    schedules,
    savePolicyOnSchedule,
    loadDependencies,
    isMod,
  } = useRouteState(ScheduleRouteState, {
    policyId: id,
  })

  const methods = useForm<FormValues>()
  const { watch, setValue, handleSubmit, reset } = methods
  const noDigital = watch(FORM_NAME.noDigital)

  const firstAppointmentDate = policy?.firstAppointmentDate

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modifyDate, setModifyDate] = useState<Date | string | null>(null)
  const [previousDate, setPreviousDate] = useState<Date | string | null>(null)
  
  const dateSelected = getLocalStorageItem('dateSelected') || modifyDate

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setValue(FORM_NAME.firstAppointmentDate, null)
    setScheduleFilters(getFiltersObject({ noDigital }))
  }, [noDigital, setScheduleFilters, setValue])

  useEffect(() => {
    setLocalStorageItem('dateSelected', null)
    setLocalStorageItem('slotsSelected', null)
    setLocalStorageItem('previousDateSelected', null)
  }, [])

  useEffect(() => {
    if (policy) {
      reset({
        noDigital: policy.noDigital,
      })
    }
    if (isMod) {
      setModifyDate(originalFormatDate(firstAppointmentDate))
    }
  }, [policy, isMod, firstAppointmentDate, reset])

  if (!policy) return null

  const onSubmit: SubmitHandler<FormValues> = async (form) => {   
    const { originQida } = form
    form.firstAppointmentDate = getLocalStorageItem('dateSelected')
    await savePolicyOnSchedule(form, { avoidUpdate: true, originQida })
    await loadDependencies()
    setIsModalOpen(true)
  }

  const onClose = () => {
    setIsModalOpen(false)
  }

  const key = isMod ? 'common:modifyAppointment' : 'common:createAppointment'

  return (
    <div className='py-4'>
      <SRUserName fullName={policy.fullName} />
      <Form className='grid grid-cols-12 gap-8' methods={methods} dataTestId='schedule-social-referent'>
        <ScheduleNoDigitalCheckbox
          isMod={isMod}
          setModifyDate={setModifyDate}
          name={FORM_NAME.noDigital}
        />
        <ScheduleAgendaLayout
          buttonText={key}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          dateSelected={dateSelected || modifyDate} 
          firstDateSelected={firstAppointmentDate}
          previousDate={ previousDate}
          noDigital={noDigital}
          schedules={schedules}
          isMod={isMod}
          setModifyDate={(date) => {
            setModifyDate(date ? originalFormatDate(new Date(date)) : null)
          }}
          modifyDate={modifyDate ? new Date(modifyDate) : null}
        />
      </Form>
      {isModalOpen && <ConfirmationModal onClose={onClose} />}
    </div>
  )
})
