import { FORM_TYPE, Input, Label } from '@shared/ui'
import { FC } from 'react'
import { checkboxClasses } from './styles'

type SRCheckboxProps = {
  name: string
  label: string
  className?: string
}

export const SRCheckbox: FC<SRCheckboxProps> = ({
  name,
  label = '',
  className = '',
}) => {
  return (
    <div className={`${checkboxClasses} ${className}`}>
      <Input type={FORM_TYPE.checkbox} name={name} className="w-8 h-8" />
      <Label name={name} label={label} />
    </div>
  )
}
