export const CP_CATEGORY = Object.freeze({
  common: 'Portal',
  mobileNavBar: 'mobile_navbar_bottom',
  contentCardDownload: 'content_card_download'
})

export const CP_EVENT = Object.freeze({
  clickBottombarOptions: 'click',
  clickOnWorkSheet: 'N_CLICKS_FICHAS',
  reportDownload: 'CANTIDAD_DESCARGAS_INFORMES',
  totalVideoCallClicks: 'CANTIDAD_BOTON_VIDEOLLAMADA',
  totalWorksheet: 'FICHAS_TRABAJO_DISPONIBLE',
  clickEvent: 'click'
})

export const CP_PAGE_VIEW = Object.freeze({
  pageViewErrorPage: 'PAGE_VIEW_ERROR_PAGE',
  pageViewHome: 'PAGE_VIEW_HOME',
  pageViewPost: (id: string) => `PAGE_VIEW_POST_${id}`,
  pageViewVideo: (id: string) => `PAGE_VIEW_VIDEO_${id}`,
})


export const CP_EVENT_LABEL = Object.freeze({
  reportDownload: 'DOWNLOAD_REPORT_BUTTON',
  bottomBarMenu: 'BOTTOM_BAR_MENU',
})


export const VIDEO_CALL_PAGE_VIEW = Object.freeze({
  pageViewGetReady: 'PAGE_VIEW_GET_READY',
  pageViewPermissionsRequest: 'PAGE_VIEW_PERMISSIONS_REQUEST',
  pageViewVideoCallPreview: 'PAGE_VIEW_VIDEO_CALL_PREVIEW',
  pageViewVideoCallPlay: 'PAGE_VIEW_VIDEO_CALL_PLAY',
  pageViewPermissionsIssue: 'PAGE_VIEW_PERMISSIONS_ISSUE',
})
