import { Textarea } from '@shared/ui'
import { useFormContext } from 'react-hook-form'
import {
  errorSpanClassName,
  textareaClassName,
  selectErrorClassName,
} from '../styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type SRTextareaProps = {
  children?: unknown
  id?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  name: string
  minlenght?: number
  maxlenght?: number
  cols?: number
  rows?: number
  disabled?: boolean
  className?: string
  isVisible?: boolean
}

export const SRTextarea: FC<SRTextareaProps> = ({
  children,
  id,
  value,
  defaultValue,
  placeholder,
  name,
  minlenght,
  maxlenght,
  disabled,
  className = '',
  cols = 4,
  rows = 4,
  isVisible,
}) => {
  const { t } = useTranslation()
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = Object.keys(errors).some((key) =>
    key.startsWith('professionalDesc'),
  )

  return (
    <div className={` flex flex-col ${className} w-full`}>
      <Textarea
        className={` ${
          isVisible ? 'visible' : 'hidden w-0 h-0 p-0'
        } ${textareaClassName} ${hasError ? selectErrorClassName : ''}`}
        name={name}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        min={minlenght}
        max={maxlenght}
        disabled={disabled}
        cols={cols}
        rows={rows}
      >
        {children}
      </Textarea>
      {hasError && (
        <span
          className={`${
            isVisible ? 'visible' : 'hidden w-0 h-0 p-0'
          }${errorSpanClassName}`}
        >
          * {t('textAreaError')}
        </span>
      )}
    </div>
  )
}
