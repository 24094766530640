import { ProfileForm } from '@sr/routes/common/generic-profile/enums'
import { AddressProps } from '@sr/routes/common/generic-profile/types'
import { SRInput } from '@sr/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const EditableFields: FC<AddressProps> = ({
  street,
  postalCode,
  locality,
  region,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={'address-block__paragraph'}>
        <SRInput
          name={ProfileForm.street}
          defaultValue={street}
          placeholder={t('common:street')}
        />
        <SRInput
          name={ProfileForm.postalCode}
          defaultValue={postalCode}
          placeholder={t('common:postalCode')}
        />
      </div>
      <div className={'address-block__paragraph'}>
        <SRInput
          name={ProfileForm.locality}
          defaultValue={locality}
          placeholder={t('common:locality')}
        />

        <SRInput
          name={ProfileForm.region}
          defaultValue={region}
          placeholder={t('common:region')}
        />
      </div>
    </>
  )
}
