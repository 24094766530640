import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import './styles.scss'

interface SwitcherProps {
  label?: string
  dataSentPostal?:string
  initialChecked?: 'off' | 'on' | 'disabled'
  status?: 'off' | 'on' | 'disabled'
  disabled?: boolean
  onChange?: (state: 'off' | 'on' | 'disabled') => void
}

export const Switcher: React.FC<SwitcherProps> = ({
  label,
  initialChecked = 'off',
  dataSentPostal= '',
  status,
  disabled = false,
  onChange,
}) => {

  const { t } = useTranslation()

  const [state, setState] = useState<'off' | 'on' | 'disabled'>(
    disabled ? 'disabled' : initialChecked
  )

  const [ labelChanged, setLabelChanged ] = useState(label)

  useEffect(() => {
    setState(disabled ? 'disabled' : initialChecked)
  }, [initialChecked, disabled])

  const handleToggle = () => {
    if (state !== 'disabled') {
      const newState = state === 'off' ? 'on' : 'off'
      setState(newState)
      onChange?.(newState)
    }

    if(state === 'on') {
      setLabelChanged(t('analysis:toggleOptions.offLabel'))
    } else if(state === 'off') {
      setLabelChanged(t('analysis:toggleOptions.onLabel'))
    }

  }

  const containerClass = `flex items-center ${state === 'disabled' ? 'cursor-not-allowed' : 'cursor-pointer'}`
  const switcherClass = `block w-14 h-8 rounded-full transition-colors ${state === 'on' ? 'bg-primary' : 'bg-input-disabled-bg'}`

  const dotClass = `dot absolute left-[4px] top-[4px] w-6 h-6 rounded-full transition-transform 
    ${state === 'on' 
      ? 'translate-x-full bg-white' 
      : state === 'off' 
        ? 'translate-x-0 bg-white' 
        : 'bg-dot-disabled-bg'}`


  return (
    <div className="flex w-full justify-end items-start align-top">
      <label htmlFor="toggle" className={containerClass}>
        <div className="relative">
          <input
            type="checkbox"
            id="toggle"
            checked={state === 'on'}
            onChange={handleToggle}
            className="sr-only"
            disabled={state === 'disabled'}
          />
          <div className={switcherClass}></div>
          <div className={dotClass}></div>
        </div>
        {label && (
          <div className={`flex flex-col text-xs items-end self-end w-[75px] ${state === 'disabled' ? 'text-gray-500' : 'text-gray-700'}`}>
            <span className='min-h-[16px] len-4'>{labelChanged}</span>
            <span className='min-h-[16px] len-4'>{dataSentPostal && moment(dataSentPostal).format('DD/MM/YYYY')}</span>
          </div>
        )}
      </label>
    </div>
  )
}
