import { observer } from 'mobx-react-lite'
import { CarouselAgenda } from '@shared/ui'
import { dateBannerClasses } from './styles'

export const SRScheduleAgenda = observer(
  ({
    schedules = [],
    dateSelected = null,
    fieldName = '',
    noDigital = false,
    isMod = false,
    setModifyDate,
    modifyDate,
  }) => {
    return (
      <CarouselAgenda
        dateBannerClasses={dateBannerClasses}
        noDigital={noDigital}
        schedules={schedules}
        dateSelected={dateSelected}
        fieldName={fieldName}
        isMod={isMod}
        modifyDate={modifyDate}
        setModifyDate={setModifyDate}
      />
    )
  },
)
