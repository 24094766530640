import { AddressProps } from '@sr/routes/common/generic-profile/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ReadOnlyFields: FC<AddressProps> = ({
  street,
  postalCode,
  locality,
  region,
}) => {
  const { t } = useTranslation()
  const isEmpty = !street && !postalCode && !locality && !region

  if (isEmpty) return <div>{t('common:notIndicated')}</div>

  return (
    <>
      <div>
        {street && <span>{`${street}, `}</span>}
        {postalCode && <span>{postalCode}</span>}
      </div>
      <div>
        {locality && <span>{`${locality}, `}</span>}
        {region && <span>{region}</span>}
      </div>
    </>
  )
}
