import ReactGA from 'react-ga4'
import { PageViewParams, InitParams, EventParams } from './types'

class Analytics {
  public init({ userId }: InitParams) {
    const GAId = process.env['NX_GA_ID']

    if (GAId) {
      ReactGA.initialize(GAId, {
        gaOptions: {
          userId,
        },
      })
    }
  }

  public pageView({ path, title, data, page, referrer }: PageViewParams) {
    ReactGA.send({ 
      hitType: 'pageview', 
      path: path, 
      page: page, 
      referrer: referrer,
      title, 
      ...data })
  }

  public event({ category, action, value, label }: EventParams) {
    ReactGA.event({ 
      category, 
      action, 
      value,
      label })
  }
}

export const analytics = new Analytics()
