import { getDateFormatted, LANGUAGES } from '@shared/utils'
import { UserPersonalDataProps } from '../types'
import { GENDER_NAMES } from '@shared/data-access'
import { AddressBlock } from '../components'
import { ProfileForm } from '../enums'
import { EditableType } from '@sr/ui'

export const getCampaignPersonalData = ({
  t,
  isEditing,
  name,
  surname,
  phone,
  email,
  identification,
  preferredLanguage = LANGUAGES.es,
  sex,
  birthDay,
  postalCode,
  locality,
  region,
  street,
  socialReferent,
  policyNumber,
  contractNumber,
}: UserPersonalDataProps) => [
  { label: t('common:name'), value: name },
  { label: t('common:surname'), value: surname },
  {
    name: ProfileForm.phone,
    label: t('common:phone'),
    value: phone,
    isEditing,
  },
  ...(policyNumber
    ? [{ label: t('common:policyNumber'), value: policyNumber }]
    : []),
  {
    name: ProfileForm.email,
    label: t('common:email'),
    value: email,
    isEditing,
  },
  {
    name: ProfileForm.identification,
    label: t('common:identification'),
    value: identification,
    isEditing,
  },
  {
    name: ProfileForm.preferredLanguage,
    label: t('common:language'),
    value: t(`common:languages.${preferredLanguage}`),
    isEditing,
    editableType: EditableType.select,
    selectOptions: [
      { name: t(`common:languages.es`), value: 'es' },
      { name: t(`common:languages.ca`), value: 'ca' },
    ],
    selectValue: preferredLanguage,
  },
  ...(contractNumber
    ? [{ label: t('common:contractNumber'), value: contractNumber }]
    : []),
  {
    name: ProfileForm.sex,
    label: t('common:sex'),
    value: sex
      ? t(
          `common:gender.${
            GENDER_NAMES[Number(sex) as keyof typeof GENDER_NAMES]
          }`,
        )
      : null,
    isEditing,
    editableType: EditableType.select,
    selectOptions: [
      { name: t(`common:gender.male`), value: 1 },
      { name: t(`common:gender.female`), value: 2 },
    ],
    selectValue: sex,
  },
  {
    name: ProfileForm.birthDay,
    label: t('common:birthDay'),
    value: birthDay,
    formattedValue: getDateFormatted(birthDay, 'DD/MM/YYYY'),
    isEditing,
    editableType: EditableType.date,
  },
  ...(socialReferent
    ? [{ label: t('common:socialReferent'), value: socialReferent }]
    : []),

  {
    label: t('common:address'),
    value: (
      <AddressBlock
        street={street}
        postalCode={postalCode}
        locality={locality}
        region={region}
        isEditing={isEditing}
      />
    ),
    hasToGrow: true,
  },
]

export const getCarePersonalData = ({
  t,
  isEditing,
  name,
  surname,
  phone,
  email,
  identification,
  preferredLanguage = LANGUAGES.es,
  sex,
  birthDay,
  locality,
  region,
  socialReferent,
  policyNumber,
  contractNumber,
}: UserPersonalDataProps) => [
  { label: t('common:name'), value: name },
  { label: t('common:surname'), value: surname },
  ...(policyNumber
    ? [{ label: t('common:policyNumber'), value: policyNumber }]
    : []),
  {
    label: t('common:identification'),
    value: identification,
  },
  {
    label: t('common:birthDay'),
    value: birthDay,
    formattedValue: getDateFormatted(birthDay, 'DD/MM/YYYY'),
  },
  ...(contractNumber
    ? [{ label: t('common:contractNumber'), value: contractNumber }]
    : []),  
  {
    name: ProfileForm.phone,
    label: t('common:phone'),
    value: phone,
    isEditing,
  },
  {
    name: ProfileForm.email,
    label: t('common:email'),
    value: email,
    isEditing,
  },
  {
    name: ProfileForm.preferredLanguage,
    label: t('common:language'),
    value: t(`common:languages.${preferredLanguage}`),
    isEditing,
    editableType: EditableType.select,
    selectOptions: [
      { name: t(`common:languages.es`), value: 'es' },
      { name: t(`common:languages.ca`), value: 'ca' },
    ],
    selectValue: preferredLanguage,
  },
  {
    label: t('common:address'),
    value: `${locality}, ${region}`,
  },
  {
    name: ProfileForm.sex,
    label: t('common:sex'),
    value: sex
      ? t(
          `common:gender.${
            GENDER_NAMES[Number(sex) as keyof typeof GENDER_NAMES]
          }`,
        )
      : null,
    isEditing,
    editableType: EditableType.select,
    selectOptions: [
      { name: t(`common:gender.male`), value: 1 },
      { name: t(`common:gender.female`), value: 2 },
    ],
    selectValue: sex,
  },
  ...(socialReferent
    ? [{ label: t('common:socialReferent'), value: socialReferent }]
    : []),
]
