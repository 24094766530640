export enum TEST_TYPES {
  initialAnalysis = 1,
  preventionPlan = 2,
}

export const TEST_TYPE_NAME = Object.freeze({
  [TEST_TYPES.initialAnalysis]: 'testList.initialAnalysis',
  [TEST_TYPES.preventionPlan]: 'testList.preventionPlan',
})

export enum FORM_FIELDS {
  testType = 'testType',
}
