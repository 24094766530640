import { SRModal } from '@sr/ui'
import { FC } from 'react'
import { ModalContainer } from './styles'
import { InformationFilled } from '@carbon/icons-react'
import { useTranslation } from 'react-i18next'
import { Button, BUTTON_SIZES } from '@shared/ui'

type SuccessModalProps = {
  onClose: () => void
  opName: string
  count?: number
}

export const SuccessModal: FC<SuccessModalProps> = ({
  onClose,
  opName,
  count,
}) => {
  const { t } = useTranslation()

  console.log(count)
  return (
    <SRModal onClose={onClose}>
      <ModalContainer>
        <div className="w-[50px] h-[50px] text-primary">
          <InformationFilled className="w-full h-full fill-primary" />
        </div>
        <div className="text-center">
          {t('privateArea.portfolioManagement.modal.successMessage', {
            count,
            opName,
          })}
        </div>
        <Button
          className="self-end"
          size={BUTTON_SIZES.small}
          onClick={onClose}
        >
          {t('common:continue')}
        </Button>
      </ModalContainer>
    </SRModal>
  )
}
