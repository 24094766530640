import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { getDateFormatted, useRouteState, getLocalStorageItem, changeTimeZoneDate } from '@shared/utils'

import { ConfirmationModalRouteState } from './state'
import { ConfirmationModalProps } from '../../interfaces'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'

import { SRModal } from '@sr/ui'
import { Button } from '@shared/ui'

import { CheckmarkOutline } from '@carbon/icons-react'

export const ConfirmationModal: React.FC<ConfirmationModalProps> = observer(({ onClose }) => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { policy } = useRouteState(ConfirmationModalRouteState, {
    policyId: id,
  })
  const navigate = useNavigate()

  if (!policy) return null

  const date = getDateFormatted(getLocalStorageItem('dateSelected'), 'DD/MM/YYYY')
  const time = getDateFormatted(getLocalStorageItem('dateSelected'), 'HH:mm')

  const handleContinue = () => {
    navigate(`${ROUTE_PATH.userArea(id!)}`)
  }

  return (
    <SRModal onClose={onClose} >
      <div className='flex flex-col justify-center items-center gap-4 mb-4 text-primary' data-testid='prevent-plan-postal'>
        <CheckmarkOutline size={36} />
        <div className='font-semibold'>{t('schedule.modal.confirmation.title')}</div>
        <div className='text-center'>
          {t('schedule.modal.confirmation.userInfo', {
            userName: policy?.fullName,
            srName: policy?.socialReferent?.fullName,
          })}
        </div>
        <div className='flex justify-center items-center gap-2'>
          <div>{t('schedule.modal.confirmation.date', { date })}</div>
          <div>{t('schedule.modal.confirmation.time', { time })}</div>
        </div>
        <Button onClick={handleContinue}>{t('common:continue')}</Button>
      </div>
    </SRModal>
  )
})
