import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  AppointmentInfo,
  DoubleModal,
  EVENT_FORM,
  useModalForm,
} from '../../common'
import { useSchemaEvent } from './schema'
import { useModalContext } from '../../provider'
import { getDateFromExactTime, useRouteState } from '@shared/utils'
import { CareForm } from './care-form'
import { CareAppointmentState } from './state'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { APPOINTMENT_TYPES } from '@shared/data-access'

export const Care = observer(
  ({ isReadOnly, isEdit, actionAfterSubmit, isCoordinator, onClose }) => {
    const { t } = useTranslation()
    const { id: socialReferentId } = useParams()
    const { setIsLoading } = useLayoutContext()
    const {
      eventId,
      appointmentType,
      appointmentDay,
      startTime,
      endTime,
      policyId,
      modality,
      needsTracking,
    } = useModalContext()
    const {
      isLoading,
      policyClients,
      canGenerateRoom,
      saveAppointment,
      createAppointment,
      setAppointmentType,
      isOwner,
      setSearch,
      policy,
    } = useRouteState(CareAppointmentState, {
      eventId,
      isEdit,
      socialReferentId,
      appointmentType,
      policyId,
    })
    const onChange = (e) => {
      setSearch(e.target.value)
    }
    const schema = useSchemaEvent(t, isEdit, needsTracking)
    const defaultValues = {
      [EVENT_FORM.appointmentType]: appointmentType,
      [EVENT_FORM.appointmentDay]: appointmentDay,
      [EVENT_FORM.appointmentPolicy]: policyId || '',
      [EVENT_FORM.startDate]: startTime,
      [EVENT_FORM.endDate]: endTime,
      [EVENT_FORM.appointmentModality]: modality,
    }

    const { methods } = useModalForm({ schema, defaultValues, isEdit })

    const { handleSubmit } = methods

    const onSubmit = handleSubmit((form) => {
      const {
        appointmentType,
        appointmentPolicy,
        appointmentModality,
        appointmentModificationOrigin,
        appointmentModificationReason,
      } = form
      const { startDate, endDate } = form
      const newStartDate = getDateFromExactTime(appointmentDay, startDate)
      const newEndDate = getDateFromExactTime(appointmentDay, endDate)

      const eventForm = {
        startDate: newStartDate,
        endDate: newEndDate,
        appointmentType,
        id: eventId || '',
        policyId: appointmentPolicy || policyId,
        socialReferentId,
        appointmentModality: Number(appointmentModality),
        ...(needsTracking && {
          appointmentModificationReason,
          appointmentModificationOrigin,
        }),
      }

      const action = isEdit ? saveAppointment : createAppointment
      action(eventForm).then(() => {
        actionAfterSubmit()
      })
    })

    const [isEditing, setIsEditing] = useState(false)
    const toggleEdit = () => {
      setIsEditing(!isEditing)
    }

    useEffect(() => {
      setIsLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
      setAppointmentType(appointmentType)
    }, [appointmentType])

    const showResendComms = Boolean(
      appointmentType === APPOINTMENT_TYPES.INITIAL_ANALYSIS ||
        appointmentType === APPOINTMENT_TYPES.INITIAL_REPORT ||
        appointmentType === APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE,
    )

    return (
      <DoubleModal
        onClose={onClose}
        isEdit={isEdit}
        isReadOnly={isReadOnly}
        methods={methods}
        toggleEdit={toggleEdit}
        isEditing={isEditing}
        formSlot={
          <CareForm
            isCoordinator={isCoordinator}
            isReadOnly={isReadOnly}
            isEdit={isEdit}
            policyClients={policyClients}
            isEditing={isEditing}
            needsTracking={needsTracking}
            searchSelectProps={{ onChange }}
            officeStreet={policy?.completeOfficeAddress}
          />
        }
        infoSlot={
          <AppointmentInfo
            canGenerateRoom={canGenerateRoom?.result}
            profileUrl={ROUTE_PATH.userArea(policyId)}
            isCoordinator={isCoordinator}
            isOwner={isOwner}
            isEditing={isEditing}
            showResendComms={showResendComms}
          />
        }
        onSubmit={onSubmit}
      />
    )
  },
)
