import { useEffect, useState } from 'react'

import { getLocalStorageItem } from '@shared/utils'
import { getFiltersObject } from '@shared/ui'

import { CarouselDots } from '../carousel-dots'
import { Carousel } from '../carousel/carousel'
import { Schedule } from '../schedule'

export const CarouselAgenda = ({
  policyId,
  noDigital,
  schedules,
  setFilters,
  dateSelected,
  fieldName,
  isMod,
  modifyDate,
  setModifyDate,
}) => {

  const noDigitalLocal = getLocalStorageItem('no_digital') 


  useEffect(() => {
    if (noDigitalLocal !== undefined && noDigitalLocal !== null) {
      if (noDigitalLocal === false) {
        setFilters({})
      } else {
        setFilters(getFiltersObject({ noDigital }))
      }
    }
  }, [noDigitalLocal, setFilters])

  console.log("🚀 ~ groupSchedules ~ noDigital:", noDigital)
  console.log("🚀 ~ noDigitalLocal:", noDigitalLocal)
  console.log("🚀 ~ schedules:", schedules)

  const groupSchedules = () => {
      if (!noDigitalLocal && !noDigital) {      
        const groupedByFortyNineItems = []
        for (let i = 0; i < schedules.length; i += 49) {
          groupedByFortyNineItems.push(schedules.slice(i, i + 49))
        }
        return groupedByFortyNineItems
    } else {
      const groupedBySlots = []
      let slot = []
      for (let i = 0; i < schedules.length; i++) {
        slot.push(schedules[i])
        if (slot.length === 14) { 
          groupedBySlots.push(slot)
          slot = []
        }
      }
      if (slot.length > 0) {
        groupedBySlots.push(slot)
      }

      return groupedBySlots
    }
  }
  const groupedSchedules = groupSchedules()


  const [date, setDate] = useState(null)

  useEffect(() => {
    const newDate = isMod ? modifyDate : dateSelected
    setDate(newDate)
  }, [isMod, modifyDate, dateSelected])

  return (
    <Carousel DotsComponent={CarouselDots}>
      {groupedSchedules.map((group, index) => (
        <Schedule
          key={index}
          noDigital={noDigital}
          schedules={group}
          fieldName={fieldName}
          isMod={isMod}
          modifyDate={modifyDate}
          setModifyDate={setModifyDate}
        />
      ))}
    </Carousel>
  )
}

