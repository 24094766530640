import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

type TextareaProps = {
  children?: unknown
  id?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  name: string
  min?: number
  max?: number
  rows?: number
  cols?: number
  disabled?: boolean
  className?: string
  autoFocus?: boolean
  onBlur?: () => void
  onChange?: () => void
  onKeyDown?: () => void
}

export const Textarea: FC<TextareaProps> = ({
  name,
  value,
  defaultValue,
  placeholder,
  id,
  disabled,
  autoFocus,
  onBlur,
  className,
  onChange,
  onKeyDown,
  min,
  max,
  rows,
  cols,
}) => {
  const { register } = useFormContext()
  const uniqueId = `textareaText${id || name}`

  return (
    <textarea
      {...register(name, { onBlur, onChange, min, max, disabled })}
      id={uniqueId}
      data-testid={`inputText${name}`}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      autoFocus={autoFocus}
      className={className}
      minLength={min}
      maxLength={max}
      onKeyDown={onKeyDown}
      rows={rows}
      cols={cols}
      key={name}
    />
  )
}
