import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useRouteState, setLocalStorageItem } from '@shared/utils'

import { AnalysisRouteState } from './state'
import { ModalType, useAnalysisModal } from './hooks'

import {
  GenericAnalysis,
  PDFPreview,
  useLayoutContext,
} from '@sr/routes/common'

import { VALIDATE_ALL } from './enums'
import { Attributes, HistoricalSent } from './interfaces'

type OnChangePageParams = {
  activePage: React.ReactElement
  prevPage: React.ReactElement
}

type SelectParams = {
  page: number
  id: string
}

type AnalysisProps = {
  personId: string
  reportType: number
}

export const Analysis: FC<AnalysisProps> = observer(
  ({ personId, reportType }) => {
    const { testId } = useParams()
    const { t } = useTranslation()
    const {
      isLoading,
      typeList,
      setAutoValidate,
      modalType,
      reload,
      resend,
      test,
      setModal,
      enableOptionsPostal,
      // enableOptionsPortal,
      // enableOptionsEmail,
      person,
      fetchHistoricalSentByPostal,
      sendByPostal,
      generateInform,
      // fetchHistoricalSentByPortal,
      // fetchHistoricalSentByEmail,
      historicalPostal,
      // historicalPortal,
      // historicalEmail,
    } = useRouteState(AnalysisRouteState, {
      personId,
      reportType,
      testId,
    })

    const location = useLocation()

    const [isCompleting, setIsCompleting] = useState<boolean>(false)
    const [initialStatusPostal, setInitialStatusPostal]= useState<'on'| 'off' | 'disabled'>('disabled')
    const [hasPostal, setHasPostal] = useState<Attributes | undefined>(undefined)
    // const [initialStatusPortal, setInitialStatusPortal]= useState<boolean>(false)
    // const [initialStatusEmail, setInitialStatusEmail]= useState<boolean>(false)

    const [activePageNumber, setActivePageNumber]: [
      number,
      Dispatch<SetStateAction<number>>
    ] = useState(0)

    const { setIsLoading } = useLayoutContext()

    const onSelectTest = ({ page, id }: SelectParams) => {
      setAutoValidate(id)
      setActivePageNumber(page)
    }

    const onPreviewError = () => {
      setAutoValidate(VALIDATE_ALL)
      setActivePageNumber(0)
    }

    const onChangePage = ({ activePage }: OnChangePageParams) => {
      setActivePageNumber(activePage?.props?.testOrder)
    }

    const onClose = () => {
      setModal(null)
      setIsCompleting(false)
    }

    const Modal = useAnalysisModal({
      modalType,
      reload,
      resend,
      onClose,
      fullName: person?.fullName,
    })

    const generateReport = () => { 
      setIsCompleting(true)
      setModal(ModalType.confirmation)
    }

    // Use the fetchHistoricalSent action when the component mounts
    useEffect(() => {
      if (testId) {
        fetchHistoricalSentByPostal(testId)
        // fetchHistoricalSentByPortal(testId)
        // fetchHistoricalSentByEmail(testId)
      }
    }, [testId])

    const extractAttributes = (data: HistoricalSent[]) => {
      if (data.length === 0) return
  
      const lastElement = data.slice(-1)[0]
  
      const { attributes } = lastElement || {}
      setHasPostal(attributes)
    }

    useEffect(() => {
      setIsLoading(isLoading)

      // Postal
      if(historicalPostal) {
        if(historicalPostal.data.length > 0 || enableOptionsPostal){
          setInitialStatusPostal('on')
          extractAttributes(historicalPostal.data)
        } else {
          setInitialStatusPostal('disabled')
        }
      }
      // Portal
      // if(enableOptionsPortal === null) {
      //   setInitialStatusPortal('disabled').
      // } else if(enableOptionsPortal) {
      //   setInitialStatusPostal('off')
      // }
      // Email
      // if(enableOptionsEmail === null) {
      //   setInitialStatusEmaill('disabled')
      // } else if(enableOptionsEmail) {
      //   setInitialStatusEmaill('off')
      // }
    }, [isLoading, historicalPostal])

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search)
      const passFakedTest = queryParams.get('passFakedTest')
  
      if (passFakedTest) {
        setLocalStorageItem('annuity', true)
        setLocalStorageItem('generic', true)
        setLocalStorageItem('control', true)
        setLocalStorageItem('analysis', true)
        setLocalStorageItem('initial', true)
      }
    }, [location])

    return (
      <>
        <GenericAnalysis
          testList={typeList}
          activePageNumber={activePageNumber}
          onChangePage={onChangePage}
          onComplete={test?.isFilled && generateReport}
          generateInform={generateInform}
        >
          <PDFPreview
            name={t('analysis:end.title')}
            onSelectTest={onSelectTest}
            onPreviewError={onPreviewError}
            testOrder={typeList?.length}
            isCompleting={isCompleting || isLoading}
            statusPostal={initialStatusPostal|| 'disabled'}
            sendByPostal={() => sendByPostal(testId)}
            dataPostal={hasPostal?.sentDate}
            // statusPortal={initialStatusPortal}
            // statusEmail={initialStatusEmail}
            reportType={reportType}
          />
        </GenericAnalysis>
        {modalType && Modal}
      </>
    )
  }

)
