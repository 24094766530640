import { FC } from 'react'
import { ErrorItem } from './error-item'
import {
  errorListContainerClasses,
  errorListUlContainerClasses,
} from './styles'
import { useTranslation } from 'react-i18next'

export type ListItem = {
  id: string
  isFilled: boolean
  testType: number
  testOrder: number
}

type TestParams = {
  page: number
  id: string
}

type Props = {
  list: ListItem[]
  onSelectTest: (testParams: TestParams) => void
}

export const ErrorList: FC<Props> = ({ list, onSelectTest }) => {
  const { t } = useTranslation()
  if (!list)
    return (
      <div>
        <h3 className='flex flex-col gap-4 w-full max-h-[your_max_height] overflow-y-auto'>
          {t('analysis:pdfPreview.errorList.title')}
        </h3>
        <ul className={errorListUlContainerClasses}>
          <li className="list-item">
            {t('analysis:pdfPreview.errorList.emptyList')}
          </li>
        </ul>
      </div>
    )

  return (
    <div className={errorListContainerClasses}>
      <h3 className='flex flex-col gap-4 w-full max-h-[your_max_height] overflow-y-auto'>
        {t('analysis:pdfPreview.errorList.title')}
      </h3>
      <ul className={errorListUlContainerClasses}>
        {list?.map((test: ListItem) => {
          return (
            <ErrorItem
              test={test}
              onSelectTest={onSelectTest}
              key={`error-item-${test.id}`}
            />
          )
        })}
      </ul>
    </div>
  )
}
