import tw from 'tailwind-styled-components'

export const appointmentListContainerClasses = `
    py-6
`

export const headerContainerClasses = `
    grid
    items-center
    text-xl
    text-primary
    p-3
    border-b
    border-primary
`

export const headerTitleClasses = `
    flex
    items-center
    gap-3
    basis-0
    grow
    first:pl-4
`

export const itemsListContainer = `
    grid
    gap-4
    grid-cols-12
    px-4
    pt-2
`

export const ItemsListLine = tw.div`
    col-span-12
    relative
    pl-4
    pr-0
    md:pr-4
    space-y-10
`

export const RowContainerClasses = tw.li`
    flex
    flex-row
    md:grid
    md:gap-4
    relative
    pt-1
`

export const rowItemClasses = `
    flex
    items-center
    flex-1
    min-h-[40px]
`

export const appointmentRowBorders = `
    border-b
    border-b-slate-500/50
`
export const appointmentRowItem = `
    h-12
    align-bottom
`
export const appointmentRowTypeItem = `
    ${appointmentRowItem}
    flex
    items-end
`
export const appointmentRowPlaceItem = `
    ${appointmentRowItem}
    flex
    items-end
    justify-between
`

export const appointmentSectionTitle = `
    text-primary
    text-2xl
    w-11/12
    m-auto
    mb-6
`

export const appointmentTable = `
    w-11/12
    m-auto
    mb-16
`

export const appointmentHeaders = `
    text-justify
    text-primary
    mr-12
`
