import { BUTTON_COLORS, Button, FORM_TYPE, Label } from '@shared/ui'
import { SRInput, SRSelect, SRTimeInput } from '@sr/ui'
import { useTranslation } from 'react-i18next'
import {
  UNAVAILABLE_SLOTS_TYPES,
  UNAVAILABLE_SLOT_TRANSLATION,
} from '@sr/data-access'
import {
  EVENT_FORM,
  ModalAgendaActionButton,
  ModalAgendaFormContainer,
  getAbsencesOptions,
  getAppointmentOptions,
} from '../../common'
import { CheckBoxContainer } from './styles'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

export const AbsencesForm = ({ isReadOnly, isEdit, onSubmit, onDelete }) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()
  const [isAllDay, setIsAllDay] = useState(false)
  const TRANSLATION_BASE_KEY = 'agenda.formModal.labels'

  const allDay = watch(EVENT_FORM.allDay)

  const getUnavailableOptions = Object.values(UNAVAILABLE_SLOTS_TYPES).map(
    (value) => ({
      value,
      name: t(UNAVAILABLE_SLOT_TRANSLATION[value] || ''),
    })
  )

  const appointmentTypes =
    !isEdit || isReadOnly ? getAppointmentOptions(isEdit) : getAbsencesOptions()

  useEffect(() => {
    setIsAllDay(allDay)
  }, [allDay])

  return (
    <>
      <ModalAgendaFormContainer>
        <SRSelect
          name={EVENT_FORM.appointmentType}
          options={appointmentTypes}
          disabled={isReadOnly}
        />
        <SRSelect
          name={EVENT_FORM.unavailableSlotType}
          options={getUnavailableOptions}
          disabled={isReadOnly}
        />
        <SRInput
          name={EVENT_FORM.appointmentDay}
          type={FORM_TYPE.date}
          min={new Date().toISOString().split('T')[0]}
          disabled={isReadOnly}
        />
        <SRTimeInput
          startName={EVENT_FORM.startDate}
          endName={EVENT_FORM.endDate}
          disabled={isReadOnly || isAllDay}
        />
        {!isReadOnly && (
          <CheckBoxContainer>
            <SRInput
              name={EVENT_FORM.allDay}
              type={FORM_TYPE.checkbox}
              disabled={isReadOnly}
            />
            <Label name={EVENT_FORM.allDay}>
              {t(`${TRANSLATION_BASE_KEY}.allDay`)}
            </Label>
          </CheckBoxContainer>
        )}
      </ModalAgendaFormContainer>
      <ModalAgendaActionButton $isEdit={isEdit}>
        {isEdit && (
          <Button
            testId="event-form-cancel-button"
            onClick={onDelete}
            disabled={isReadOnly}
            color={BUTTON_COLORS.secondary}
          >
            {t('agenda.formModal.cancelAbsenceButton')}
          </Button>
        )}
        <Button
          testId="event-form-submit-button"
          onClick={onSubmit}
          disabled={isReadOnly}
        >
          {t('agenda.formModal.saveButton')}
        </Button>
      </ModalAgendaActionButton>
    </>
  )
}
