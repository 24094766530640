import moment from 'moment'
import 'moment-timezone'


export const formatDateDifference = (dateString) => {
  const today = moment()
  const postDate = moment(dateString)
  const diffDays = today.diff(postDate, 'days')
  return diffDays
}
export const getToday = () => {
  return moment()
}

export const getLastWorkDayFromToday = (days = 1) => {
  let date = moment(getToday())
  while (days > 0) {
    date = date.subtract(1, 'days')
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1
    }
  }
  return date
}

export const getDayFrom = (date, extraDay) => {
  return moment(date).add(extraDay, 'd')
}

export const getDateFormatted = (date, format = 'lll') => {
  if (!date) return ''

  const formats = {
    lll: 'lll',
    LLLL: 'dddd, D [de] MMMM YYYY[ - ]H:mm[h]',
  }

  const dateFormat = formats[format] || format
  return moment(date).format(dateFormat)
}

export const getDateFormattedFromSchedule = (date, format = 'lll', newTime = null) => {
  if (!date) return ''

  // Create a moment object from the date string
  const dateMoment = moment(date)

  // If newTime is provided, adjust the time
  if (newTime) {
    const [hours, minutes] = newTime.split(':').map(Number)
    dateMoment.hour(hours).minute(minutes).second(0) // Set new time, reset seconds
  }

  const formats = {
    lll: 'lll', // Example: Aug 12, 2024 07:00 AM
    LLLL: 'dddd, D [de] MMMM YYYY[ - ]H:mm[h]', // Example: Monday, 12 de August 2024 - 07:00h
  }

  const dateFormat = formats[format] || format
  return dateMoment.format(dateFormat)
}

export const originalFormatDate = (date) => {
  const dateFormat = moment(date)
  return dateFormat.format('YYYY-MM-DDTHH:mm:ssZ')
}
export const undoAppointmentManagerFormatDate = (date) => {
  const dateFormat = moment(date, 'dddd, D [de] MMMM YYYY - HH:mm[h]')
  return dateFormat.format('YYYY-MM-DDTHH:mm:ssZ')
}
export const getDateHeadPDF = (date, locale = 'es-ES', setDay) => {
  let options = {
    year: 'numeric',
    month: 'long',
  }

  if (setDay) {
    options.day = 'numeric'
  }

  let dateString = new Date(date).toLocaleDateString(locale, options)

  dateString = dateString
    .split(' ')
    .map((word, index) => {
      if (locale.startsWith('es') || locale.startsWith('ca')) {
        if ((setDay && index === 2) || (!setDay && index === 0)) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }
      }
      return word.toLowerCase()
    })
    .join(' ')

  if (locale.startsWith('es')) {
    dateString = dateString.replace(/ De /g, ' de ')
  } else if (locale.startsWith('ca')) {
    dateString = dateString.replace(/ D’/g, ' d’')
  }

  return dateString
}

export const getWorkDays = (startDate, daysRequired = 7) => {
  let days = []

  for (let i = 0; days.length < daysRequired; i++) {
    const day = moment(startDate).add(i, 'days')
    const isSaturday = day.isoWeekday() === 6
    const isSunday = day.isoWeekday() === 7

    if (!isSaturday && !isSunday) {
      days.push(day)
    }
  }
  return days
}

export const getWorkDayFromToday = (days) => {
  let date = moment(getToday())
  while (days > 0) {
    date = date.add(1, 'days')
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1
    }
  }
  return date
}

export const getWorkDayFromDate = (workDate, days) => {
  let date = moment(workDate)
  while (days > 0) {
    date = date.add(1, 'days')
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1
    }
  }
  return date
}

export const addMinutes = ({ date, minutes }) => {
  return moment(date).add(minutes, 'minutes')
}

export const diffDaysFromToday = (date) => {
  return getToday().diff(moment(date), 'days')
}

export const diffDaysFromdate = (date1, date2) => {
  return moment(date1).diff(moment(date2), 'days')
}

export const compareDates = (firstDate, secondDate) => {
  return (
    parseToUserTimeZone(firstDate, {
      dateStyle: 'short',
      timeStyle: 'short',
    }) ===
    parseToUserTimeZone(secondDate, {
      dateStyle: 'short',
      timeStyle: 'short',
    })
  )
}

export const differenceBetweenDateAndToday = (date) => {
  const formatter = 'DD/MM/YYYY HH:mm:ss'

  return moment(new Date(date), formatter).diff(moment(new Date(), formatter))
}

export const differenceBetweenDates = (date1, date2) => {
  return moment(date1).diff(moment(date2))
}

export const getDayWithExactHour = (date, { hour, minute, seconds = 0 }) => {
  const convertedDate = convertToDate(date)
  const year = convertedDate.getUTCFullYear()
  const month = convertedDate.getUTCMonth()
  const day = convertedDate.getUTCDate()

  const timeZoneDate = parseToUserTimeZone(Date.UTC(year, month, day))

  if (!isInvalidDate(timeZoneDate)) {
    const unixDate = convertToDate(timeZoneDate).setHours(
      hour,
      minute,
      seconds,
    )
    return convertToDate(unixDate)
  }
    
}

export const parseToSpanishZone = (date, options = {}) => {
  return convertToDate(date).toLocaleString('en-US', {
    timeZone: 'Europe/Madrid',
    ...options,
  })
}

export const parseToUserTimeZone = (date, options = {}) => {
  const userTimeZone = moment.tz.guess()
  const format = options.format || 'D/M/YYYY, h:mm:ss A'
  return moment(date).tz(userTimeZone).format(format)
}

export const convertDateDependingTimeZone = (date) => {
  const userTimeZone = moment.tz.guess()
  const targetZone = 'Europe/Madrid'
  const timeInCurrentZone = moment.tz(date, 'HH:mm', userTimeZone)
  const timeInTargetZone = timeInCurrentZone.clone().tz(targetZone)
  const formattedTimeInTargetZone = timeInTargetZone.format('HH:mm')
  return formattedTimeInTargetZone
}

export const changeTimeZoneDate = (date) => {
  const userTimeZone = moment.tz.guess()

  if (userTimeZone === 'Atlantic/Canary') {
    let parsedDate = moment(date, 'HH:mm')
    parsedDate.subtract(1, 'hours')
    
    let newDate = parsedDate.format('HH:mm')
    return newDate
  }
}

export function adjustDateString(dateStr) {
  const dateTimeFormat = 'dddd D MMMM, HH:mm - HH:mm'
  let fullDate = moment(dateStr, dateTimeFormat, 'es')
  let startTime = fullDate.format('HH:mm')
  let adjustedStartTime = changeTimeZoneDate(startTime)
  let adjustedDateStr = fullDate.format('dddd D MMMM') + ', ' + adjustedStartTime

  return adjustedDateStr
}

export function formatDate(dateStr) {
  moment.locale('es')
  const fullDate = moment(dateStr).format('dddd, D [de] MMMM')

  return fullDate
}

export const convertDateToUtc = (date) => {
  return moment.tz(new Date(date), 'Europe/Berlin')
}

export const convertToDate = (date) => {
  return new Date(date)
}

export const isInvalidDate = (date) => {
  let parsedDate = Date.parse(date)
  return isNaN(parsedDate)
}

export const getHour = (date) => {
  return moment(date).hour()
}

export const getMinute = (date) => {
  return moment(date).minutes()
}

// This function convert date with some zone to UTC with spanish offset
export const convertDateToSpanishZone = (date) => {
  return moment(date).tz('UTC').format('YYYY-MM-DDTHH:mm:ssZ')
}

export const getTimeFromTodayInText = (date) => {
  return moment(date).calendar(getToday())
}

export const getDateFromExactTime = (date, time = '') => {
  let [hour, minute] = time.split(':')

  return getDayWithExactHour(date, { hour, minute })
}

export const getTime = (date) => {
  return moment(date).format('HH:mm')
}

export const getTimeInGMT = (date) => {
  return moment(date).local().format('HH:mm')
}

export const isGreaterTime = (end, start) => {
  return moment(end, 'HH:mm').isSameOrAfter(moment(start, 'HH:mm'))
}

export const getJSDate = (day) => {
  return moment(day, 'DD/MM/YYYY').toDate()
}

export const getDateFromNow = (date) => {
  if (!date) return ''
  return moment(date).fromNow()
}
