import { Input } from '@shared/ui'
import { useFormContext } from 'react-hook-form'
import {
  errorSpanClassName,
  inputClassName,
  selectErrorClassName,
} from '../styles'
import { FC } from 'react'

type SRInputProps = {
  children?: unknown
  id?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  name: string
  type?: string
  min?: number
  max?: number
  disabled?: boolean
  className?: string
}

export const SRInput: FC<SRInputProps> = ({
  children,
  id,
  value,
  defaultValue,
  placeholder,
  name,
  type = 'text',
  min,
  max,
  disabled,
  className = '',
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors[name]
  const errorMessage = errors[name]?.message?.toString()

  return (
    <div
      className={`flex flex-col ${className} ${
        disabled ? 'cursor-not-allowed' : ''
      } `}
    >
      <Input
        className={`${inputClassName} ${hasError ? selectErrorClassName : ''} ${
          disabled ? 'pointer-events-none  opacity-75' : ''
        }`}
        name={name}
        type={type}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
      >
        {children}
      </Input>
      {hasError && (
        <span className={`${errorSpanClassName} max-w-[280px]`}>
          * {errorMessage}
        </span>
      )}
    </div>
  )
}
